import React from 'react'
import styled from 'styled-components'

const CardCol = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin: 0 auto;
  margin-bottom: 32px;
        
  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }
  
  @media only screen and (min-width:36em) {
    flex-basis: 50%;
    max-width: 50%;
    display: block;
  }
  
  @media only screen and (min-width:62em) {
    flex-basis: 33.333333333333336%;
    max-width: 33.333333333333336%;
    display: block;
  }
  
  @media (min-width:992px) {
    margin: 0;
    padding: 16px;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px;
  overflow: hidden;
  position: relative;
  height: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: all 500ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;

  a {
    transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  
  }

  :hover,
  :focus {
    box-shadow: 0 6px 26px rgba(0, 0, 0, 0.09);
    transform: translateY(-4px);
    
  }
`;

const CardContent = styled.div`
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;

  *:last-child {
    margin-bottom: 0;
    text-align: left;
  }

  p {
      color: #2D2F3D;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.6;
      margin: 0 0 0 0;
      font-weight: 500;
      padding-top: 0px !important;

      @media (min-width:576px) {
          font-size: 21px;
      }
  }
`;

const CardLinkContainer = styled.div`
  margin-top: 34px;
`;
const RadioCardInput = styled.input`
border: 0px none;
clip: rect(0px, 0px, 0px, 0px);
height: 1px;
margin: -1px;
overflow: hidden;
padding: 0px;
position: absolute;
white-space: nowrap;
width: 1px;
`;

const RadioCard = props => (

<CardCol>
      <CardBody>
        <CardContent>
            <RadioCardInput id={props.producttitle + '-' + props.productsubtitle} type="radio" name={props.producttitle + '-' + props.productsubtitle}></RadioCardInput>
            <label for={props.producttitle + '-' + props.productsubtitle}>
                <div>
                    <span>
                        <svg viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
                    </span>
                </div>
                <p>{props.producttitle}</p>
                <p>{props.productsubtitle}</p>
            </label>
        </CardContent>
      </CardBody>
</CardCol>

)
export default RadioCard
