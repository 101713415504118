import React, {Component} from 'react'
import styled from 'styled-components'
import Container from '../container'
import Row from '../row'
import Checkbox from './checkbox'
import SelectIcon from "../../../assets/selecticon.svg"

const PageContactContainer = styled.div`
  background-color: rgb(252, 252, 252);
  align-items: center;
  display: flex;
`;

const PageContactCol = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 48px;
  text-align: center;

  @media only screen and (min-width:0em) {
        flex-basis: 100%;
        max-width: 100%;
        display: block;
  }

  @media only screen and (min-width:62em) {
        flex-basis: 58.333333333333336%;
        max-width: 58.333333333333336%;
        display: block;
  }

  p {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const PageContactH2 = styled.h2`
  color: rgb(45, 47, 61);
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
  margin: 0 0 12px 0;

  @media (min-width:576px) {
      font-size: 36px;
  }
`;

const FormContainer = styled.div`
  box-sizing: border-box;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;

  }

  @media only screen and (min-width:62em) {
    flex-basis: 77%;
    max-width: 77%;
    margin-left: -0.75rem;
    margin-right: -0.75em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Flexcolumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: left;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: 12px;
  padding-bottom: 12px;

  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }

  @media only screen and (min-width:62em) {
    flex: 1;
  }
`;
const FlexCol2 = styled(Flexcolumn)`
  flex: 2;
 
  @media only screen and (min-width:62em) {
    flex: 2;
  }
`
const FormLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  color: rgb(29, 29, 29);
  font-size: 16px;
`;
const FormSelectDiv = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;
const FormSelect = styled.select`
  cursor: pointer;
  width: 100%;
  padding: 12px;
  padding-right: 36px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;
  font-size: 16px;
  line-height: 1.3;
  color: rgb(45, 47, 61);
  background-color: #FFFFFF;
  height: 50px;

  ::-webkit-input-placeholder {
    color: #61626D;
  }

  ::-ms-expand {
    display: none;
  }
`;
const FormSelectIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 2px;
  top: 0;
  width: 40px;

  svg {
    font-size: 24px;
  }
`;
const FormInput = styled.input`
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #E8E8E8;
  color: rgb(45, 47, 61);
  display: block;
  font-size: 16px;
  padding: 12px;
  width: 100%;

  ::-webkit-input-placeholder {
    color: rgb(45, 47, 61);
  }
`;
const FormMessage = styled.textarea`
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #E8E8E8;
  display: block;
  font-size: 16px;
  max-width: 100%;
  padding: 12px;
  width: 100%;
  min-height: 170px;
  color: rgb(45, 47, 61);

  ::-webkit-input-placeholder {
    color: rgb(45, 47, 61);
  }
`;
const CheckboxLabel = styled.label`
  cursor: pointer;
`;
const CheckboxText= styled.span`
  margin-right: 0px;
  color: rgb(29, 29, 29);
`;
const FormButton = styled.button`
  margin: 12px auto 0px;
  min-width: 155px;
  background-color: #004FEF;
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  text-decoration: none;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: auto;
  color: #FFFFFF;
  position: relative;

  :hover,
  :active,
  :focus {
    background-color: rgb(25, 96, 240);
  }

`;

class PageContact extends Component {

  state = { 
    checked: false, 
    value: 0
  }

  handleCheckboxChange = event => {
    this.setState({ checked: event.target.checked })
    this.setState({ value: this.checked ? 0 : 1})
  }



  render () {
    var returl = null;

    if (this.props.currentlang === "de"){
      returl ="https://www.berlitz-augsburg.de/kontakt/ihre-anfrage/";
    } else {
      returl ="https://www.berlitz-augsburg.de/en/contact/your-inquiry/";
    }

    return (
      <PageContactContainer>
        {this.props.currentlang === "de" &&
          <Container>
            <Row>
              <PageContactCol>
                <PageContactH2>{this.props.heading}</PageContactH2>
                <p>{this.props.intro}</p>
              </PageContactCol>
                <FormContainer>
                  <form name="main-contact" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" >
                    <input name="recordType" type="hidden" id="recordType" value="0121t000000dYn5" />
                    <input type="hidden" name="oid" value="00D0b000000uiVk"/>
                    <input type="hidden" id="00N0b00000BXXYk" maxlength="255" name="00N0b00000BXXYk" size="20" value="00G3W000000ySYVUA2"  />
                    <input type="hidden" id="00N0b00000BXGrY" name="00N0b00000BXGrY" title="Country Segmentation" value="Germany" />
                    <input name="Website_Name__c" type="hidden" value="https://www.berlitz-augsburg.de/kontakt/"></input>
                    <input name="Form_URL__c" type="hidden" value="https://www.berlitz-augsburg.de/kontakt/" />
                    <input name="Campaign_Source__c" id="utm_source" type="hidden" value=""></input>
                    <input name="Campaign_Name__c" id="utm_campaign" type="hidden" value=""></input>
                    <input name="Campaign_Medium__c" id="utm_medium" type="hidden" value=""></input>
                    <input name="Experiment__c" id="utm_term" type="hidden" value=""></input>
                    <input type="hidden" name="lead_source" value="Website" />
                    <input type="hidden" id="00N0b00000BXPM9" name="00N0b00000BXPM9" title="Delivery Center" value="Augsburg" />
                    <input type="hidden" name="retURL" value={returl} />
                    <input type="hidden" name="form-name" value="main-contact" />
                    <Row>          
                      <Flexcolumn>
                      <FormLabel for="salutation">Anrede<span>*</span></FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="salutation" name="salutation" required>
                            <option selected="" value="">Bitte wählen</option>
                            <option value="Ms">Frau</option>
                            <option value="Mr">Herr</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <svg width="1em" height="1em" viewBox="0 0 24 24" focusable="false" aria-hidden="true" role="presentation"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" fill="currentColor"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg>
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                      <Flexcolumn>              
                        <FormLabel for="first_name">Vorname<span>*</span></FormLabel>
                        <FormInput id="first_name" maxlength="80" name="first_name" type="text" required />
                      </Flexcolumn>
                      <Flexcolumn>
                      <FormLabel for="last_name">Nachname<span>*</span></FormLabel>
                        <FormInput id="last_name" maxlength="80" name="last_name" type="text" required />
                      </Flexcolumn>
                    </Row>
                    <Row>          
                      <Flexcolumn>
                      <FormLabel for ="email">E-Mail<span>*</span></FormLabel>
                        <FormInput id="email" maxlength="80" name="email" size="20" type="text" required/>
                      </Flexcolumn>
                      <Flexcolumn>
                      <FormLabel for="phone">Telefon</FormLabel>
                        <FormInput id="phone" maxlength="40" name="phone" size="20" type="text"/>
                      </Flexcolumn>
                      <Flexcolumn>
                        <FormLabel for="zip">PLZ<span>*</span></FormLabel>
                        <FormInput id="zip" maxlength="20" name="zip" size="20" type="text" required/>
                      </Flexcolumn>
                    </Row>
                    <Row>          
                      <Flexcolumn>
                        <FormLabel for="00N0b00000BXGrU">Wofür interessieren Sie sich?<span>*</span></FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="00N0b00000BXGrU" name="00N0b00000BXGrU" required>
                            <option selected="" value="">Bitte wählen</option>
                            <option value="F2F Adult">Sprachunterricht - Face-to-Face</option>
                            <option value="Online">Sprachunterricht - Online</option>
                            <option value="Kids">Kids &amp; Teens Sprachkurse</option>
                            <option value="KidsCamps">Kids &amp; Teens Camps</option>
                            <option value="Non Language">Berlitz Seminare</option>
                            <option value="Study Abroad">Im Ausland lernen</option>
                            <option value="Other">Andere</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <svg width="1em" height="1em" viewBox="0 0 24 24" focusable="false" aria-hidden="true" role="presentation"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" fill="currentColor"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg>
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                      <Flexcolumn>
                        <FormLabel for="language">In welcher Sprache?<span>*</span></FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" required>
                            <option value="">Bitte wählen</option>
                            <option value="Italian">Englisch</option>
                            <option value="German">Deutsch</option>
                            <option value="Spanish">Spanisch</option>
                            <option value="French">Französisch</option>
                            <option value="Italian">Italienisch</option>
                            <option value="Other">Andere Sprache</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                    </Row>
                    <Row>          
                      <Flexcolumn>
                        <FormLabel for="description">Nachricht</FormLabel>
                        <FormMessage name="description" id="description"/>
                      </Flexcolumn>
                    </Row>
                    <Row>          
                      <Flexcolumn>
                        <CheckboxLabel>
                          <Checkbox checked={this.state.checked} onChange={this.handleCheckboxChange} value={this.state.value}/>
                          <CheckboxText>Bitte senden Sie mir per E-Mail weitere Informationen und Angebote der Berlitz Sprachschule in Augsburg zu. Meine Einwilligung kann ich jederzeit per E-Mail an datenschutz@berlitz-augsburg.de widerrufen.</CheckboxText>
                        </CheckboxLabel>
                      </Flexcolumn>
                    </Row>
                    <Row>
                      <FormButton type="submit" name="submit" required>
                        Anfrage senden
                      </FormButton>
                    </Row>
                  </form>
                </FormContainer>
            </Row>
          </Container>
        }
        {this.props.currentlang === "en" &&
          <Container>
            <Row>
              <PageContactCol>
                <PageContactH2>{this.props.heading}</PageContactH2>
                <p>{this.props.intro}</p>
              </PageContactCol>
                <FormContainer>
                  <form name="main-contact-en" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                    <input name="recordType" type="hidden" id="recordType" value="0121t000000dYn5" />
                    <input type="hidden" name="oid" value="00D0b000000uiVk"/>
                    <input type="hidden" id="00N0b00000BXXYk" maxlength="255" name="00N0b00000BXXYk" size="20" value="00G3W000000ySYVUA2" />
                    <input type="hidden" id="00N0b00000BXGrY" name="00N0b00000BXGrY" title="Country Segmentation" value="Germany" />
                    <input name="Website_Name__c" type="hidden" value="https://www.berlitz-augsburg.de/en/contact/"></input>
                    <input name="Form_URL__c" type="hidden" value="https://www.berlitz-augsburg.de/en/contact/" />
                    <input name="Campaign_Source__c" id="utm_source" type="hidden" value=""></input>
                    <input name="Campaign_Name__c" id="utm_campaign" type="hidden" value=""></input>
                    <input name="Campaign_Medium__c" id="utm_medium" type="hidden" value=""></input>
                    <input name="Experiment__c" id="utm_term" type="hidden" value=""></input>
                    <input type="hidden" name="lead_source" value="Website" />
                    <input type="hidden" id="00N0b00000BXPM9" name="00N0b00000BXPM9" title="Delivery Center" value="Augsburg" />
                    <input type="hidden" name="retURL" value={returl} />
                    <Row>          
                      <Flexcolumn>
                        <FormLabel id="salutation" for="salutation">Prefix<span>*</span></FormLabel>
                        <FormSelectDiv>
                            <FormSelect id="salutation" name="salutation" required>
                              <option selected="" value="">Please Select</option>
                              <option value="Ms">Ms./Mrs.</option>
                              <option value="Mr">Mr.</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                      <Flexcolumn>              
                        <FormLabel for="first_name">First Name<span>*</span></FormLabel>
                        <FormInput type="text" name="first_name" id="first_name" required />
                      </Flexcolumn>
                      <Flexcolumn>
                        <FormLabel for="last_name">Last Name<span>*</span></FormLabel>
                        <FormInput type="text" name="last_name" id="last_name" required />
                      </Flexcolumn>
                    </Row>
                    <Row>          
                      <FlexCol2>
                        <FormLabel for ="email">E-Mail<span>*</span></FormLabel>
                        <FormInput type="text" name="email" id="email" required/>
                      </FlexCol2>
                      <FlexCol2>
                        <FormLabel for="phone">Phone</FormLabel>
                        <FormInput type="phone" name="phone" id="phone"/>
                    </FlexCol2>
                    <Flexcolumn>
                        <FormLabel for="zip">Zip Code<span>*</span></FormLabel>
                        <FormInput type="zip" name="zip" id="zip" required/>
                    </Flexcolumn>
                    </Row>
                    <Row>          
                      <Flexcolumn>
                        <FormLabel for="00N0b00000BXGrU">What is your service of interest?<span>*</span></FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="00N0b00000BXGrU" name="00N0b00000BXGrU" required>
                              <option value="">Please Select</option>
                              <option value="F2F Adult">Language Training - Face to Face</option>
                              <option value="Online">Language Training - Online</option>
                              <option value="Kids">Kids &amp; Teens Language Programs</option>
                              <option value="KidsCamps">Kids &amp; Teens Camps</option>
                              <option value="Subsidized Courses">Subsidized Courses</option>
                              <option value="Study Abroad">Study Abroad</option>
                              <option value="Other">Other</option> 
                            </FormSelect>
                            <FormSelectIcon>
                              <span>
                                <SelectIcon />
                              </span>
                            </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                      <Flexcolumn>
                        <FormLabel for="00N0b00000BXGt8">Language of interest?<span>*</span></FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" required>
                            <option value="">Please Select</option>
                            <option value="English">English</option>
                            <option value="German">German</option>
                            <option value="Spanish">Spanish</option>
                            <option value="French">French</option>
                            <option value="Italian">Italian</option>
                            <option value="Other">Other Language</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                    </Row>
                    <Row>          
                      <Flexcolumn>
                        <FormLabel for="description">Message</FormLabel>
                        <FormMessage name="description" id="description"/>
                      </Flexcolumn>
                    </Row>
                    <Row>          
                      <Flexcolumn>
                        <CheckboxLabel>
                          <Checkbox checked={this.state.checked} onChange={this.handleCheckboxChange} value={this.state.value}/>
                          <CheckboxText>I would like to receive special offers via the free Berlitz Augsburg email newsletter. I can revoke my consent at any time by sending an email datenschutz@berlitz-augsburg.de or by using the unsubscribe link in the newsletter.</CheckboxText>
                        </CheckboxLabel>
                    </Flexcolumn>
                    </Row>
                    <Row>
                      <FormButton type="submit" required>
                        Find out more
                      </FormButton>
                    </Row>
                  </form>
                </FormContainer>
            </Row>
          </Container>
        }
      </PageContactContainer>  
    )
  }
}

export default PageContact;
