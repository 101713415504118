import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill'
import Row from '../row'
import { Link } from 'gatsby'

const CardContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
`;

const CardButtonRow = styled(Row)`
  padding-bottom: 32px;
  text-align: left;
  justify-content: left !important;
`;

const CardRow = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0;
  padding-left: 0;


  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }

  @media only screen and (min-width:62em) {
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
    display: block;
  }
`;

const CardWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 200px;

  @media (min-width:768px) {
    flex-direction: row;
    min-height: 483px;
  }
`;

const CardImageWrapper = styled.div`
@supports (display:-webkit-box) or (display:-webkit-flex) or (display:-ms-flexbox) or (display:flex) {
  flex: 1 1 0%;
}

@media (min-width:768px) {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  justify-content: center;
}
`;

const FeatureCardImg = styled(Img)`
max-height: 100% !important;
height: 100% !important;
width: 100% !important;

  @media (max-width:768px) {
  min-height: 160px;
  }

`;

const CardContent = styled.div`
  padding: 24px;
  text-align: left;

  @supports (display:-webkit-box) or (display:-webkit-flex) or (display:-ms-flexbox) or (display:flex) {
    flex: 1 1 0%;
  }

  @media (min-width:768px) {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    justify-content: center;
    margin: 32px 0;
    padding: 48px;
  }
  ol {
    list-style-type: decimal;
    column-gap: 64px;
    padding-left: 32px;
    color: inherit;
    margin: 0px;
  }
  li {
    font-size: 18px;
    position: relative;
    padding: 4px 0px;
    break-inside: avoid;
    width: 100%;
    margin: 0px 0px 8px;
    font-weight: 300;
    line-height: 1.6;
    color: rgb(45, 47, 61);
  }
  p {
    margin-top: 24px;
  }
  h2 {
    font-size: 36px;
  }
  @media (max-width:768px) {
    h2 {
      font-size: 24px;
    }
}
`;

const Flexcolumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: left;
  padding: 0;
  margin-bottom: 12px;
  padding-bottom: 12px;

  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }

  @media only screen and (min-width:62em) {
    flex: 1;
  }
  @media (min-width:576px) {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}  
`

const ListCardsSection = styled.section`

`;

const FeatureList = styled.ul`
  column-gap: 64px;
  padding-left: 32px;
  padding-bottom: 24px;
  color: inherit;
  margin: 0px;

  li {
    position: relative;
    padding: 4px 0px;
    break-inside: avoid;
    width: 100%;
    color:
    rgb(45, 47, 61);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6;
    margin: 0px 0px 8px;
    font-size: 18px;
    
    :before {
      content: "●";
      font-family: "Arial, Helvetica, sans-serif";
      display: inline-block;
      position: absolute;
      left: -24px;
      top: 10px;
      font-size: 12px;
      color:
      rgb(0, 79, 239);
    }
  }


`;

const CardLink = styled(Link)`
  min-width: 118px;
  background: none;
  background-color: rgb(0, 79, 239);
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  text-decoration: none;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: auto;
  color: #FFFFFF;
  position: relative;
  text-transform: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  :hover,
  :active,
  :focus {
    background-color: rgb(25, 96, 240);
    border: none;
    color: #FFFFFF;
  }

`;

const CardLinkContainer = styled.div`
  margin-top: 34px;
  padding-bottom: 10px;
`;

const SingleButtonContainer = styled(CardLinkContainer)`
  max-width: 145px;
`;

const FeatureCardListReverse= props => (
<ListCardsSection>
        <CardContainer>
        <CardRow>
            <CardWrapper>
                <CardContent>
                    <h2>{props.cardtitle}</h2>
                    {props.cardsubtitle &&
                      <p><strong>{props.cardsubtitle}</strong></p>
                    }
                    <FeatureList>
                    {props.carditems.map(item => (
                      <React.Fragment>
                        <li>{item.listitem}</li>
                      </React.Fragment>
                    ))}
                    </FeatureList>
                    {props.buttons &&
                      <React.Fragment>
                      <CardButtonRow>
                        <strong><span>{props.languageselect}</span></strong>
                      </CardButtonRow>
                      <Row>
                        {props.buttons.map(item => (
                          <Flexcolumn>
                            <CardLink to={item.buttonlink}>{item.button}</CardLink>
                          </Flexcolumn>
                        ))}
                      </Row>
                      </React.Fragment>
                    }
                    {props.singlebutton &&
                    <SingleButtonContainer>
                      <CardLink to={props.singlebuttonlink}>{props.singlebutton}</CardLink>
                    </SingleButtonContainer>
                    }
                </CardContent>
                <CardImageWrapper>
                  <FeatureCardImg fixed={props.cardimage} objectPosition={props.imageposition} durationFadeIn="200" loading="eager"/>
                </CardImageWrapper>
            </CardWrapper>
        </CardRow>
        </CardContainer>
</ListCardsSection>
);
export default FeatureCardListReverse; 

