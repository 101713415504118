import React from 'react'
import styled from 'styled-components'
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel,} from 'react-accessible-accordion'

const AccordionButton = styled(AccordionItemButton)`
  padding: 20px 0;
  cursor: pointer;
  width: 100%;
  text-align: inherit;
  outline: none;
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  h3 {
    color: rgb(45, 47, 61);
    font-weight: 400;
    margin-bottom: 0;
    font-size: 15px;
  }

  @media (min-width:768px) {
      outline-color: -webkit-focus-ring-color;
      outline-style: none;

      h3 {
        font-size: 21px;
      }
  }
  ::after {
    display: inline-block;
    content: '';
    height: 8px;
    width: 8px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    color: rgb(29, 29, 29);
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
  &[aria-expanded='true']::after {
    transform: rotate(-135deg);
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
`;
const AccordionHeading = styled(AccordionItemHeading)`
  display: block;
  border-bottom: 1px solid #F6F6F6;
  color: rgb(29, 29, 29);
  outline-style: none !important;
`;
const AccordionPanel = styled(AccordionItemPanel)`
padding: 30px;



h4 {
  font-weight: 500;
}
`;

function TestAccordion(props)  {


return (
<Accordion allowZeroExpanded={true}>
  {props.tests.map(item => (
    <AccordionItem>
      <AccordionHeading>
        <AccordionButton>
          <h3>{item.testtitle}</h3>
        </AccordionButton>
      </AccordionHeading>
      <AccordionPanel>
        <p>{item.testdescription}</p>
      </AccordionPanel>
    </AccordionItem>
  ))} 
</Accordion>
)
}
export default TestAccordion