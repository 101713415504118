import React, { useState } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/seo/mainseo'
import PageBannerOG from '../components/pagebanner/pagebanner'
import MainNav from '../components/navigation/mainnav'
import PageBannerMessage from '../components/pagebanner/pagebannermessage'
import Container from '../components/container'
import CenteringDiv from '../components/centeringdiv'
import IntroDiv from '../components/introdiv'
import ContentContainer from '../components/contentcontainer'
import ContentBlock from '../components/contentblock'
import ContentBlockBackground from '../components/contentblockbackground'
import FeatureCard from '../components/cards/featurecard'
import FeatureCardList from '../components/cards/featurecardlist'
import FeatureCardListReverse from '../components/cards/featurecardlistreverse'
import Card from '../components/cards/linkcard'
import ListCards from '../components/cards/listcards'
import RichCard from '../components/cards/richcard'
import ButtonCards from '../components/cards/buttoncard'
import CardDeck from '../components/cards/carddeck'
import PageBlock from '../components/pageblock'
import PageContact from '../components/forms/pagecontact-sf'
import Checkout from '../components/forms/checkout'
import BreadCrumbs  from '../components/breadcrumbs'
import BreadCrumbsTopLevel  from '../components/breadcrumbstoplevel'
import MainContact from '../components/forms/maincontact-sf'
import Levels from '../components/levels'
import TestAccordion from '../components/testaccordion'
import CourseTable  from '../components/coursetable/coursetablev1'
import SiteMap from '../components/sitemap'

const DefaultPage = ({ data, html }) => {
  const { markdownRemark: post } = data
  var delink = "de";
  var enlink = "en";
  const metaUrlalt="https://www.berlitz-augsburg.de/"
  const metaUrl="https://www.berlitz-augsburg.de"

  if (post.frontmatter.language === "de"){
    delink = `${metaUrl}${post.fields.slug}`
    enlink = `${metaUrlalt}${post.frontmatter.seo.altlanguagelink}`;
  } else if (post.frontmatter.language === "en") {
    delink = `${metaUrlalt}${post.frontmatter.seo.altlanguagelink}`;
    enlink = `${metaUrl}${post.fields.slug}`
  }
  const [course, setCourse] = useState("");
  return (
    <Layout currentlang={post.frontmatter.language} langlinkde={delink} langlinken={enlink}>
      <Seo language={post.frontmatter.language}
           pageslug={post.fields.slug}
           title={post.frontmatter.seo.metatitle}
           imgtitle={post.frontmatter.title}
           imgtitleen={post.frontmatter.imagetitle}
           description={post.frontmatter.seo.metadescription}
           lastbreadcrumb={post.frontmatter.lastbreadcrumb}
           pagetype={post.frontmatter.templateKey}
           altlanglink={post.frontmatter.seo.altlanguagelink}
           hreflangalt={post.frontmatter.seo.hreflangalt}
      />
      {post.frontmatter.language === "de" &&
        <PageBannerOG title={post.frontmatter.title} alt={post.frontmatter.displaytitle} isHome={post.frontmatter.ishomepage}>
          <MainNav langlinkde={delink} langlinken={enlink} currentlang={post.frontmatter.language}/>
          <PageBannerMessage supertitle={post.frontmatter.supertitle} pageTitle={post.frontmatter.displaytitle} pageSubtitle={post.frontmatter.pagesubtitle} isHome={post.frontmatter.ishomepage} currentlang={post.frontmatter.language}/>
        </PageBannerOG>
      }
      {post.frontmatter.language === "en" &&
        <PageBannerOG title={post.frontmatter.imagetitle} alt={post.frontmatter.displaytitle} isHome={post.frontmatter.ishomepage}>
          <MainNav langlinkde={delink} langlinken={enlink} currentlang={post.frontmatter.language}/>
          <PageBannerMessage supertitle={post.frontmatter.supertitle} pageTitle={post.frontmatter.displaytitle} pageSubtitle={post.frontmatter.pagesubtitle} isHome={post.frontmatter.ishomepage} currentlang={post.frontmatter.language}/>
        </PageBannerOG>
      }
      {post.frontmatter.toplevelpage === true &&
      <BreadCrumbsTopLevel current={post.frontmatter.lastbreadcrumb}
                   currentlang={post.frontmatter.language}
                   breadcrumbcolor={post.frontmatter.breadcrumbcolor} 
      />
      }
      {post.frontmatter.toplevelpage === false &&
      <BreadCrumbs breadcrumbs={post.frontmatter.breadcrumbs} 
                  current={post.frontmatter.lastbreadcrumb}
                  currentlang={post.frontmatter.language}
                  level={post.frontmatter.toplevelpage}
                  breadcrumbcolor={post.frontmatter.breadcrumbcolor} 
      />
      }

      <section role="main">
        {post.frontmatter.leadinabout &&
          <ContentBlock background={post.frontmatter.breadcrumbcolor}>
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  {post.frontmatter.leadinheading &&
                    <h2>{post.frontmatter.leadinheading}</h2>
                  }
                  {post.frontmatter.leadinabout.map(item => (
                    <p>{item.paragraph}</p>
                  ))}
                </ContentContainer>
              </CenteringDiv>  
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.introheading &&
          <ContentBlock background={post.frontmatter.introbackground}>
            <Container>
              <CenteringDiv>    
                <ContentContainer>
                  <IntroDiv>
                  {post.frontmatter.introheading &&
                    <h2>{post.frontmatter.introheading}</h2>
                  }
                  {post.frontmatter.introcontentblocksabout &&
                   <React.Fragment>
                    {post.frontmatter.introcontentblocksabout.map(item => (
                      <p>{item.introparagraph}</p>
                    ))}
                  </React.Fragment>
                  }
                  </IntroDiv>
                </ContentContainer>
                
              </CenteringDiv>  
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.introcontent &&
          <ContentBlock background={post.frontmatter.introbackground}>
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  {post.frontmatter.introcontent.heading &&
                    <h2>{post.frontmatter.introcontent.heading}</h2>
                  }
                  {post.frontmatter.introcontent.introparagraphs &&
                    <React.Fragment>
                      {post.frontmatter.introcontent.introparagraphs.map(item => (
                        <p>{item.paragraph}</p>
                        ))}
                    </React.Fragment>
                  }
                  {post.frontmatter.introcontent.subheader &&
                    <h3>{post.frontmatter.introcontent.subheader}</h3>
                  }
                  {post.frontmatter.introcontent.list &&
                    <div className="content-list">
                       <ul>
                      {post.frontmatter.introcontent.list.map(item => (
                       <li>{item.listitem}</li>                
                      ))}
                      </ul>
                    </div>
                  }
                </ContentContainer>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.introcontentbold &&
          <ContentBlock>
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  {post.frontmatter.introcontentbold.bold &&
                    <p><strong>{post.frontmatter.introcontentbold.bold}</strong></p>
                  }
                  {post.frontmatter.introcontentbold.paragraphs &&
                    <React.Fragment>
                      {post.frontmatter.introcontentbold.paragraphs.map(item => (
                        <p>{item.paragraph}</p>
                        ))}
                    </React.Fragment>
                  }
                </ContentContainer>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.featurecard &&        
          <ContentBlock>
            <Container>
                <FeatureCard cardtitle={post.frontmatter.featurecard.cardtitle}
                            cardtext={post.frontmatter.featurecard.cardtext}
                            cardwithlink={post.frontmatter.cardwithlink}
                            cardlink={post.frontmatter.featurecard.cardlink}
                            cardbutton={post.frontmatter.featurecard.cardbutton}
                            cardimage={post.frontmatter.featurecard.cardimage.childImageSharp.fixed}
                />
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.courses  &&
          <React.Fragment>
            <ContentBlock iscards="true">
              <Container>
                <CenteringDiv>
                  <CourseTable courses={post.frontmatter.courses} 
                              ferienkurse={post.frontmatter.ferienkurse}
                              wochenkurse={post.frontmatter.wochenkurse}   
                              currentlanguage={post.frontmatter.language} 
                              assignCourse={setCourse}
                              tableheaderwochen={post.frontmatter.tableheaderwochen}
                              tableheaderferien={post.frontmatter.tableheaderferien}
                  />
                </CenteringDiv>
              </Container>
            </ContentBlock>
          </React.Fragment>
        }
        {post.frontmatter.featurecardlist &&        
          <ContentBlock>
            <Container>
                <FeatureCardList cardtitle={post.frontmatter.featurecardlist.cardtitle}
                                 carditems={post.frontmatter.featurecardlist.listitems}
                                 cardimage={post.frontmatter.featurecardlist.featurecardimage.childImageSharp.fixed}
                                 imageposition={post.frontmatter.featurecardlist.imageposition}
                                 buttons={post.frontmatter.featurecardlist.buttons}
                                 languageselect={post.frontmatter.featurecardlist.languageselect}
                                 singlebutton={post.frontmatter.featurecardlist.singlebutton}
                                 singlebuttonlink={post.frontmatter.featurecardlist.singlebuttonlink}
                />
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.featurecardlisttwo &&        
          <ContentBlock>
            <Container>
                <FeatureCardList cardtitle={post.frontmatter.featurecardlisttwo.cardtitle}
                                 carditems={post.frontmatter.featurecardlisttwo.listitems}
                                 cardimage={post.frontmatter.featurecardlisttwo.featurecardimage.childImageSharp.fixed}
                                 imageposition={post.frontmatter.featurecardlisttwo.imageposition}
                                 languageselect={post.frontmatter.featurecardlisttwo.languageselect}
                                 singlebutton={post.frontmatter.featurecardlisttwo.singlebutton}
                                 singlebuttonlink={post.frontmatter.featurecardlisttwo.singlebuttonlink}
                />
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.featurecardlistreverse &&        
          <ContentBlock>
            <Container>
                <FeatureCardListReverse cardtitle={post.frontmatter.featurecardlistreverse.cardtitle}
                                 carditems={post.frontmatter.featurecardlistreverse.listitems}
                                 cardimage={post.frontmatter.featurecardlistreverse.featurecardimage.childImageSharp.fixed}
                                 imageposition={post.frontmatter.featurecardlistreverse.imageposition}
                />
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.listcards &&
            <ContentBlock>
                <Container>
                    <ListCards cards={post.frontmatter.listcards}/>
                </Container>
            </ContentBlock>
        }
        {post.frontmatter.cards &&
          <ContentBlock iscards="true">
            <Container>
              {post.frontmatter.cardsintro &&
                <CenteringDiv style={{marginBottom: '32px', marginTop: '32px'}}>
                  <ContentContainer>
                    <IntroDiv>
                      <h2>{post.frontmatter.cardsintro.heading}</h2>
                      {post.frontmatter.cardsintro.paragraphs &&
                        <React.Fragment>
                          {post.frontmatter.cardsintro.paragraphs.map(item => (
                          <p>{item.paragraph}</p>
                          ))}  
                        </React.Fragment>
                      }
                    </IntroDiv>
                  </ContentContainer>
                </CenteringDiv>
              }
                <CardDeck>
                  {post.frontmatter.cards.map(item => (
                    <Card cardcontent={item.text}
                          cardtitle={item.cardtitle}
                          url={item.link}
                          button={item.button}
                          cardimg={item.image.childImageSharp.fixed}
                          currentlanguage={post.frontmatter.language} 
                    />
                  ))}
                </CardDeck>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.tests &&
          <ContentBlock iscards="true">
            <Container>   
              <CenteringDiv>
                <ContentContainer>
                  <TestAccordion tests={post.frontmatter.tests}/>
                </ContentContainer>
              </CenteringDiv>        
              </Container>
          </ContentBlock>
        }
        {post.frontmatter.richcardsone &&
          <ContentBlock background={post.frontmatter.richcardsone.background}>
            <Container>
              <h2 style={{ textAlign: 'center', paddingBottom: '32px' }}>{post.frontmatter.richcardsone.heading}</h2>
                <CardDeck>
                  {post.frontmatter.richcardsone.cards.map(item => (
                    <RichCard cardcontent={item.text}
                              subheading={item.subheading}
                          listitems={item.listitems}
                          url={item.link}
                          cardimg={item.image.childImageSharp.fixed}
                          currentlanguage={post.frontmatter.language} 
                    />
                  ))}
                </CardDeck>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.richcards &&
          <ContentBlock background={post.frontmatter.richcards.background}>
            <Container>
              <h2 style={{ textAlign: 'center', paddingBottom: '32px' }}>{post.frontmatter.richcards.heading}</h2>
              <CardDeck>
                {post.frontmatter.richcards.cards.map(item => (
                  <RichCard cardcontent={item.text}
                            subheading={item.subheading}
                        listitems={item.listitems}
                        url={item.link}
                        cardimg={item.image.childImageSharp.fixed}
                        currentlanguage={post.frontmatter.language} 
                  />
                ))}
              </CardDeck>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.pagecontentblocks  &&
          <ContentBlock>
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  {post.frontmatter.pagecontentblocks.map(item => (
                    <PageBlock         
                      heading={item.heading}
                      text={item.text}
                    />
                  ))}
                </ContentContainer>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.buttoncards && 
          <ContentBlock>
            <Container> 
              <CenteringDiv>
                <CardDeck>
                  {post.frontmatter.buttoncards.map(item => (
                    <ButtonCards url={item.link}
                                 buttoncardcontent={item.text}
                    />
                  ))}
                </CardDeck>
              </CenteringDiv>
              </Container>
          </ContentBlock>
        }
        {post.frontmatter.pagebody &&
          <ContentBlock background={post.frontmatter.pagebodybackground}>
            <Container>
              <CenteringDiv>
                <ContentContainer dangerouslySetInnerHTML={{ __html: post.html }}/>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.pagetype ==="contact" &&
          <ContentBlockBackground>
            <Container>
                  <MainContact heading={post.frontmatter.contactheading}
                        intro={post.frontmatter.introtext}
                        leadsource={post.frontmatter.title}
                        currentlang={post.frontmatter.language}
                  />
            </Container>
          </ContentBlockBackground>
        }
        {post.frontmatter.levels &&
          <ContentBlock background="true">
            <Container>
            {post.frontmatter.language === "de" &&
              <CenteringDiv>
                <h2>Kompetenzstufen</h2>
              </CenteringDiv>
            }
            {post.frontmatter.language === "en" &&
              <CenteringDiv>
                <h2>Proficiency Levels</h2>
              </CenteringDiv>
            }
            </Container>
            <Levels currentlang={post.frontmatter.language}/>
          </ContentBlock>
        }
        {post.frontmatter.sitemap &&
          <ContentBlock>
            <Container>
                <SiteMap currentlang={post.frontmatter.language}/>
            </Container>
          </ContentBlock>
        }

        {(post.frontmatter.pagetype ==="language") && (post.frontmatter.pagetype !=="contact") &&
          <PageContact currentlang={post.frontmatter.language}
                       formname={post.frontmatter.title}
                       leadsource={post.fields.slug}
                       pagetype={post.frontmatter.pagetype}
                       languageinterest={post.frontmatter.languageinterest} 
          />      
        }
        {post.frontmatter.pagetype !=="language" && (post.frontmatter.pagetype !=="contact") && (post.frontmatter.pagetype !=="thank-you")&&
        <PageContact currentlang={post.frontmatter.language}
                     formname={post.frontmatter.title}
                     leadsource={post.fields.slug}
                     pagetype={post.frontmatter.pagetype} 
                     selectedcourse={course}
                     anchor="kursanfrage"
                     anchoren="course-inquiry"  
        />   
        }      
      </section>
    </Layout>
  )
}

export default DefaultPage

export const pageQuery = graphql`
  query DefaultPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        templateKey
        pagetype
        title
        imagetitle
        languageinterest
        subfolder
        toplevelpage
        language
        seo {
          metatitle
          metadescription
          altlanguagelink
          hreflangalt
        }
        title       
        displaytitle
        supertitle
        contactheading
        introtext
        sitemap
        breadcrumbs {
          crumbitem
          link
        }
        lastbreadcrumb
        breadcrumbcolor
        introbackground
        cardwithlink
        pagebody
        pagebodybackground
        leadinheading
        leadinabout {
          paragraph
        }
        introheading
        introcontentblocksabout {
          introparagraph
        }
        introcontent {
          heading
          introparagraphs {
            paragraph
          }
          subheader
          list {
            listitem
          }
        }
        introcontentbold {
          bold
          paragraphs {
            paragraph
          }
        }
        ferienkurse {
          heading
          paragraphs {
            paragraph
          }
        }
        wochenkurse {
          heading
          paragraphs {
            paragraph
          }
        }
        tableheaderwochen
        tableheaderferien
        courses
        levels
        checkout
        pagecontentblocks {
          heading
          text
        }
        featurecard {
          cardtitle
          cardtext
          cardlink
          cardbutton
          cardimage {
            childImageSharp {
              fixed(width: 500, height: 500, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        featurecardlist {
          cardtitle
          listitems {
            listitem
          }
          buttons {
            button
            buttonlink
          }
          singlebutton
          singlebuttonlink
          imageposition
          languageselect
          featurecardimage {
            childImageSharp {
              fixed(width: 720, height: 720, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        featurecardlistreverse {
          cardtitle
          listitems {
            listitem
          }
          imageposition
          featurecardimage {
            childImageSharp {
              fixed(width: 720, height: 720, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        featurecardlisttwo {
          cardtitle
          listitems {
            listitem
          }
          singlebutton
          singlebuttonlink
          imageposition
          languageselect
          featurecardimage {
            childImageSharp {
              fixed(width: 720, height: 720, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        cardsintro {
          heading
          paragraphs {
            paragraph
          }
        }
        cards {
          image {
            childImageSharp {
              fixed(width: 480, height: 320, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          cardtitle
          link
          button
          text
        }
        tests {
          testtitle
          testdescription
        }
        listcards {
          cardtitle
          cardtexts {
            cardtext
          }
          cardimage {
            childImageSharp {
              fixed(width: 720, height: 720, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        richcardsone {
          heading
          background
          cards {
            link
            text
            listitems {
              listitem
            }
            image {
              childImageSharp {
                fixed(width: 480, height: 320, quality: 80) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        richcards {
          heading
          introcontent
          background
          cards {
            link
            text
            subheading
            listitems {
              listitem
            }
            image {
              childImageSharp {
                fixed(width: 480, height: 320, quality: 80) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        buttoncards {
          link
          text
        }
        products {
          producttitle
          productsubtitle
        }
      }
    }
    
  }
  
`
