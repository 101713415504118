import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const CardCol = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin: 0 auto;
  margin-bottom: 32px;
        
  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }
  
  @media only screen and (min-width:36em) {
    flex-basis: 50%;
    max-width: 50%;
    display: block;
  }
  
  @media only screen and (min-width:62em) {
    flex-basis: 33.333333333333336%;
    max-width: 33.333333333333336%;
    display: block;
  }
  
  @media (min-width:992px) {
    margin: 0;
    padding: 16px;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px;
  overflow: hidden;
  position: relative;
  height: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: all 500ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;

  a {
    transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  
  }

  :hover,
  :focus {
    box-shadow: 0 6px 26px rgba(0, 0, 0, 0.09);
    transform: translateY(-4px);
    
  }
`;

const CardContent = styled.div`
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;

  *:last-child {
    margin-bottom: 0;
    text-align: left;
  }

  p {
      color: #2D2F3D;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.6;
      margin: 0 0 0 0;
      font-weight: 500;
      padding-top: 0px !important;

      @media (min-width:576px) {
          font-size: 21px;
      }
  }
`;

const CardLinkContainer = styled.div`
  margin-top: 34px;
`;

const CardLink = styled(Link)`
    color: rgb(45, 47, 61);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
    margin: 0px;
    background: none;
`;




const ButtonCard = props => (

<CardCol>
    <Link to={props.url}>
      <CardBody>
        <CardContent>
            <CardLink to={props.url}>{props.buttoncardcontent}</CardLink>
        </CardContent>
      </CardBody>
    </Link>
</CardCol>

)
export default ButtonCard
