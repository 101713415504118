import React, {Component} from 'react'
import styled from 'styled-components'
import Container from '../container'
import Row from '../row'
import Checkbox from './checkbox'
import CardDeck from '../cards/carddeck'
import RadioCard from '../cards/radiocard'
import SelectIcon from "../../../assets/selecticon.svg"

const PageContactContainer = styled.div`
  background-color: rgb(252, 252, 252);
  align-items: center;
  display: flex;
`;

const PageContactCol = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 48px;
  text-align: center;

  @media only screen and (min-width:0em) {
        flex-basis: 100%;
        max-width: 100%;
        display: block;
  }

  @media only screen and (min-width:62em) {
        flex-basis: 58.333333333333336%;
        max-width: 58.333333333333336%;
        display: block;
  }

  p {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px;
  overflow: hidden;
  position: relative;
  height: 100%;
`;
const PricingRow = styled.div`
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  padding-bottom: 4px;
`;
const PricingRowTotal = styled.div`
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid rgb(232, 232, 232);
`;

const FormContainer = styled.div`
  box-sizing: border-box;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;

  }

  @media only screen and (min-width:62em) {

    margin-left: -0.75rem;
    margin-right: -0.75em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Flexcolumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: left;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: 12px;
  padding-bottom: 12px;

  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }

  @media only screen and (min-width:62em) {
    flex: 1;
  }
`;
const CheckoutForm = styled(Flexcolumn)`
   @media only screen and (min-width:62em) {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }
`;
const OrderOverview = styled(Flexcolumn)`
   @media only screen and (min-width:62em) {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }
`;

const FlexCol2 = styled(Flexcolumn)`
  flex: 2;
 
  @media only screen and (min-width:62em) {
    flex: 2;
  }
`
const FormLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  color: rgb(29, 29, 29);
  font-size: 16px;
`;
const FormSelectDiv = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;
const FormSelect = styled.select`
  cursor: pointer;
  width: 100%;
  padding: 12px;
  padding-right: 36px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;
  font-size: 16px;
  line-height: 1.3;
  color: rgb(45, 47, 61);
  background-color: #FFFFFF;
  height: 50px;

  ::-webkit-input-placeholder {
    color: #61626D;
  }

  ::-ms-expand {
    display: none;
  }
`;
const FormSelectIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 2px;
  top: 0;
  width: 40px;

  svg {
    font-size: 24px;
  }
`;
const FormInput = styled.input`
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #E8E8E8;
  color: rgb(45, 47, 61);
  display: block;
  font-size: 16px;
  padding: 12px;
  width: 100%;

  ::-webkit-input-placeholder {
    color: rgb(45, 47, 61);
  }
`;
const FormMessage = styled.textarea`
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #E8E8E8;
  display: block;
  font-size: 16px;
  max-width: 100%;
  padding: 12px;
  width: 100%;
  min-height: 170px;
  color: rgb(45, 47, 61);

  ::-webkit-input-placeholder {
    color: rgb(45, 47, 61);
  }
`;
const CheckboxLabel = styled.label`
  cursor: pointer;
`;
const CheckboxText= styled.span`
  margin-right: 0px;
  color: rgb(29, 29, 29);
`;
const FormButton = styled.button`
  margin: 12px auto 0px;
  min-width: 155px;
  background-color: #004FEF;
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  text-decoration: none;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
  color: #FFFFFF;
  position: relative;

  :hover,
  :active,
  :focus {
    background-color: rgb(25, 96, 240);
  }

`;

class Checkout extends Component {

  state = { 
    checked: false, 
    value: 0
  }

  handleCheckboxChange = event => {
    this.setState({ checked: event.target.checked })
    this.setState({ value: this.checked ? 0 : 1})
  }



  render () {
    var returl = null;

    if (this.props.currentlang === "de"){
      returl ="https://www.berlitz-augsburg.de/kontakt/ihre-anfrage/";
    } else {
      returl ="https://www.berlitz-augsburg.de/en/contact/your-inquiry/";
    }

    return (
      <PageContactContainer>
          <Container>
              <CheckoutForm>
              <Row>
                <FormContainer>
                  <form name={this.props.leadsource} method="POST">
                  <h3>Wählen Sie Ihre Kursdauer</h3>

                  <Row>
                    <CardDeck>
                      {this.props.products.map(item => (
                        <RadioCard producttitle={item.producttitle}
                                  productsubtitle={item.productsubtitle}
                        />
                      ))}
                    </CardDeck>
                  </Row>          
                  <h4>Kursdetails</h4>
                  <table>
                    <tr>
                      <td>Kursname</td>
                      <td>Self Study, CyberTeachers Classic: 3 Monate ( Preise inkl. Mwst)</td>
                    </tr>
                    <tr>
                      <td>Sprache</td>
                      <td>Englisch, Französisch, Deutsch, Spanisch</td>
                    </tr>
                  </table>
                  <h3>Wählen Sie aus den folgenden Optionen</h3>
                    <Row>
                        <Flexcolumn>
                            <FormLabel for="language">Sprache<span>*</span></FormLabel>
                            <FormSelectDiv>
                            <FormSelect id="language" name="salutation" required>
                                <option selected="" value="">Sprache auswählen</option>
                                <option value="Englisch">Englisch</option>
                                <option value="Französisch">Französisch</option>
                                <option value="Deutsch">Deutsch</option>
                                <option value="Spanisch" >Spanisch</option>
                            </FormSelect>
                            <FormSelectIcon>
                                <span>
                                <svg width="1em" height="1em" viewBox="0 0 24 24" focusable="false" aria-hidden="true" role="presentation"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" fill="currentColor"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg>
                                </span>
                            </FormSelectIcon>
                            </FormSelectDiv>
                        </Flexcolumn>
                        <Flexcolumn>              
                            <FormLabel for="start_date">Startdatum<span>*</span></FormLabel>
                            <FormInput id="start_date" maxlength="80" name="start_date" type="text" required />
                        </Flexcolumn>
                    </Row>
                    <h3>Rechnungsadresse</h3>
                    <Row>
                      <Flexcolumn>
                      <FormLabel for="salutation">Anrede<span>*</span></FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="salutation" name="salutation" required>
                            <option selected="" value="">Bitte wählen</option>
                            <option value="Ms">Frau</option>
                            <option value="Mr">Herr</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <svg width="1em" height="1em" viewBox="0 0 24 24" focusable="false" aria-hidden="true" role="presentation"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" fill="currentColor"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg>
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                      <Flexcolumn>              
                        <FormLabel for="first_name">Vorname<span>*</span></FormLabel>
                        <FormInput id="first_name" maxlength="80" name="first_name" type="text" required />
                      </Flexcolumn>
                      <Flexcolumn>
                      <FormLabel for="last_name">Nachname<span>*</span></FormLabel>
                        <FormInput id="last_name" maxlength="80" name="last_name" type="text" required />
                      </Flexcolumn>
                    </Row>
                    <Row>          
                      <Flexcolumn>
                      <FormLabel for ="email">E-Mail<span>*</span></FormLabel>
                        <FormInput id="email" maxlength="80" name="email" size="20" type="text" required/>
                      </Flexcolumn>
                      <Flexcolumn>
                      <FormLabel for="phone">Telefon</FormLabel>
                        <FormInput id="phone" maxlength="40" name="phone" size="20" type="text"/>
                      </Flexcolumn>
                    </Row>
                    <Row>          
                      <Flexcolumn>
                      <FormLabel for="street">Straße</FormLabel>
                        <FormInput id="phone" maxlength="40" name="phone" size="20" type="text"/>
                      </Flexcolumn>
                      <Flexcolumn>
                        <FormLabel for="zip">PLZ<span>*</span></FormLabel>
                        <FormInput id="zip" maxlength="20" name="zip" size="20" type="text" required/>
                      </Flexcolumn>
                    </Row>
                    <Row>          
                      <Flexcolumn>
                      <FormLabel for="city">Stadt</FormLabel>
                        <FormInput id="city" maxlength="40" name="phone" size="20" type="text"/>
                      </Flexcolumn>
                      <Flexcolumn>
                        <FormLabel for="country">Land<span>*</span></FormLabel>
                        <FormInput id="country" maxlength="20" name="zip" size="20" type="text" required/>
                      </Flexcolumn>
                    </Row>
                    <Row>          
                      <Flexcolumn>
                        <CheckboxLabel>
                          <Checkbox checked={this.state.checked} onChange={this.handleCheckboxChange} value={this.state.value}/>
                          <CheckboxText>Ich habe die allgemeinen Geschäftsbedingungen gelesen und akzeptiere sie *</CheckboxText>
                        </CheckboxLabel>
                      </Flexcolumn>
                    </Row>
                  </form>
                </FormContainer>
            </Row>
              </CheckoutForm>
              <OrderOverview>
                  <h4>Buchung überprüfen und zur Kasse gehen</h4>
                  <Container>
                    <Summary>
                      <h2>Bestellübersicht</h2>
                      <p>Self Study, CyberTeachers Classic: 3 Monate ( Preise inkl. Mwst)</p>
                      <PricingRow>
                        <dt>
                          <p>Zwischensumme:</p>
                        </dt>
                        <dd>99,00&nbsp;€</dd>
                      </PricingRow>
                      <PricingRow>
                        <dt>
                          <p>Sonstiges:</p>
                        </dt>
                        <dd>0,00&nbsp;€</dd>
                      </PricingRow>
                      <PricingRowTotal>
                        <dt>
                          <p>Gesamtpreis:</p>
                        </dt>
                        <dd>99,00&nbsp;€</dd>
                      </PricingRowTotal>
                      <FormButton type="submit" required>
                        Zur Kasse
                      </FormButton>
                      <p>Wir akzeptieren die folgenden Zahlungsmöglichkeiten</p>
                      <span>
                        <img src="/img/visa.jpg" width="48"/>
                      </span>
                      <span>
                        <img src="/img/mastercard.png" width="48"/>
                      </span>
                    </Summary>
                  </Container>
              </OrderOverview>
            
          </Container>
      </PageContactContainer>  
    )
  }
}

export default Checkout;

/* {post.frontmatter.checkout &&
  <Checkout products={post.frontmatter.products} />
} */